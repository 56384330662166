import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../../components/Navbar";

const AddNurseryIncomes = () => {
    const { place } = useParams()
    const [children, setChildren] = useState([])
    const [listsMonthly, setListsMonthly] = useState([])
    const [listsDaily, setListsDaily] = useState([])
    const [child, setChild] = useState('')
    const [listType, setListType] = useState('')
    const [list, setList] = useState('')
    const [type, setType] = useState('')
    const [price, setPrice] = useState('')
    const [details, setDetails] = useState('')
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState('')
    const navigate = useNavigate('')


    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        }).then((res)=>{
            window.location.reload()
        })
    };

    // Error Alert
    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    // Required Item Alert
    const showRequiredAlert = (text) => {
        Swal.fire({
            title: "مطلوب!",
            text: `${text} مطلوب.`,
            icon: "warning",
            confirmButtonText: "حسناً",
        });
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (place !== res.data) {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);


    const fetchChildren = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_HOST_SERVER}nursery/`);
            setChildren(res.data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchListsMonthly = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_HOST_SERVER}list/`);
            setListsMonthly(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    const fetchListsDaily = async () => {
        try {
            const res = await axios.get(`${process.env.REACT_APP_HOST_SERVER}dailylist/`);
            setListsDaily(res.data);
        } catch (err) {
            console.error(err);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!child||!list){
            showRequiredAlert('يرجي ادخال البيانات الاساسية (الطفل و اللائحة)')
            return;
        }
        setLoading(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}locker/incomes`, {
                place: "66fafc98cef65b167ba331ca",
                type:type? type: "دفع مصاريف اطفال",
                price:price? price: "دفع مصاريف اطفال",
                details:details? details:'دفع مصاريف للطفل '+child + ' نوع اللائحة '  + listType
            });
            showSuccessAlert('تمت اضافة الايرادات بنجاح')
            setSuccess(response.data.message);
            setError(null);
            setType('');
            setPrice('');
            setDetails('');
            setLoading(false)
        } catch (err) {
            console.log(err)
            showErrorAlert(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما'])
            setError(err.response?.data?.errors || [err.response?.data?.message || 'حدث خطأ ما']);
            setSuccess(null);
            setLoading(false)
        } finally {
            setLoading(false)
        }
    };


    useEffect(() => {
        verifyAdmin()
        fetchChildren()
        fetchListsDaily()
        fetchListsMonthly()
    }, [])
    return (
        <div>
            <Navbar />
            <div className="form w-[75%] m-auto">
                <div className="form-group mt-4">
                    <label className="text-xl">اختر الطفل</label>
                    <select
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        onChange={(e) => setChild(e.target.value)}
                    >
                        <option value={''} selected>من فضلك اختر الطفل</option>
                        {
                            children && children.map((item) => (
                                <option value={item.childName}>{item.childName}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="form-group mt-4">
                    <label className="text-xl">اختر نوع اللائحة (شهرية او يومية)</label>
                    <select
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        onChange={(e) => setListType(e.target.value)}
                    >
                        <option value={''} selected>من فضلك اختر نوع اللائحة</option>
                        <option value={"شهرية"}>{"شهرية"}</option>
                        <option value={"يومية"}>{"يومية"}</option>
                    </select>
                </div>
                {
                    listType?
                    listType === "يومية" ?
                        <div className="form-group mt-4">
                            <label className="text-xl">اختر اللائحة</label>
                            <select
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                onChange={(e) => setList(e.target.value)}
                            >
                                <option value={''} selected>من فضلك اختر اللائحة</option>
                                {
                                    listsDaily && listsDaily.map((item) => (
                                        <option value={item.listPrice}>{item.listName}</option>
                                    ))
                                }
                            </select>
                        </div> :
                        <div className="form-group mt-4">
                            <label className="text-xl">اختر اللائحة</label>
                            <select
                                className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                                onChange={(e) => setList(e.target.value)}
                            >
                                <option value={''} selected>من فضلك اختر اللائحة</option>
                                {
                                    listsMonthly && listsMonthly.map((item) => (
                                        <option value={item.listPrice}>{item.listName}</option>
                                    ))
                                }
                            </select>
                        </div>
                        :''
                }

                <div className="form-group mt-4">
                    <label className="text-xl">نوع الايرادات</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={type}
                        placeholder={"دفع مصاريف اطفال"}
                        onChange={(e) => setType(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">الثمن</label>
                    <input
                        type="number"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={price}
                        placeholder={list}
                        onChange={(e) => setPrice(e.target.value)}
                    />
                </div>
                <div className="form-group mt-4">
                    <label className="text-xl">تفاصيل</label>
                    <input
                        type="text"
                        className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                        value={details}
                        placeholder={`دفع مصاريف للطفل `+child + ' نوع اللائحة '  + listType}
                        onChange={(e) => setDetails(e.target.value)}
                    />
                </div>
                {error && (
                    <div className="mt-4 text-red-500 text-center">
                        {error}
                    </div>
                )}
                {success && (
                    <div className="mt-4 text-green-500 text-center">
                        {success}
                    </div>
                )}
                <div className="submit mt-6 text-center">
                    <button
                        type="submit"
                        className="bg-[#000000] text-white py-3 px-8 rounded w-[fit] text-xl hover:cursor-pointer hover:bg-[#000000c2] duration-200"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        أضافة الايرادات
                    </button>
                </div>

            </div>
        </div>
    );
}

export default AddNurseryIncomes;