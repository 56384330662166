import Swal from "sweetalert2";
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ContractHistory = () => {
    const [contracts, setContracts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchDate, setSearchDate] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const navigate = useNavigate();

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch {
            navigate('/login');
        }
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفها!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}contract/${id}`);
                    showSuccessAlert('تم الحذف بنجاح');
                    fetchContracts();
                } catch (err) {
                    showErrorAlert('حدث خطأ ما');
                }
            }
        });
    };


    const fetchContracts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}contract`);
            setContracts(response.data);
        } catch { }
    };

    useEffect(() => {
        verifyAdmin();
        fetchContracts();
    }, []);

    const filteredContracts = contracts.filter(contract => {
        const contractDate = new Date(contract.createdAt).toISOString().split('T')[0];
        const isMatchContract = contract.contract.toLowerCase().includes(searchTerm.toLowerCase());
        const isMatchDate = searchDate ? contractDate === searchDate : true;
        return isMatchContract && isMatchDate;
    });

    const indexOfLastContract = currentPage * itemsPerPage;
    const indexOfFirstContract = indexOfLastContract - itemsPerPage;
    const currentContracts = filteredContracts.slice(indexOfFirstContract, indexOfLastContract);
    const totalPages = Math.ceil(filteredContracts.length / itemsPerPage);

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="p-6 bg-white shadow rounded-md w-[90%] mx-auto mt-6 relative">
            <h2 className="text-2xl font-bold mb-4 text-blue-600">جدول العقود</h2>

            <input
                type="text"
                placeholder="بحث بالعقد"
                className="mb-4 p-2 border border-blue-300 rounded w-full"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <input
                type="date"
                className="mb-4 p-2 border border-blue-300 rounded w-full"
                value={searchDate}
                onChange={(e) => setSearchDate(e.target.value)}
            />

            <table className="w-full border border-gray-300 text-center">
                <thead>
                    <tr className="bg-blue-100">
                        <th className="border px-4 py-2">مكان او صاحب</th>
                        <th className="border px-4 py-2">العقد</th>
                        <th className="border px-4 py-2">قيمة العقد</th>
                        <th className="border px-4 py-2">قيمة قسط العقد</th>
                        <th className="border px-4 py-2">عدد القطع</th>
                        <th className="border px-4 py-2">سعر القطعة</th>
                        <th className="border px-4 py-2">الباقي من العقد</th>
                        <th className="border px-4 py-2">التفاصيل</th>
                        <th className="border px-4 py-2">تاريخ الإنشاء</th>
                        <th className="border px-4 py-2">إجراءات</th>
                    </tr>
                </thead>
                <tbody>
                    {currentContracts.map(contract => (
                        <tr key={contract._id}>
                            <td className="border px-4 py-2">{contract.contractPlace.place}</td>
                            <td className="border px-4 py-2">{contract.contract}</td>
                            <td className="border px-4 py-2">{contract.contractPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td className="border px-4 py-2">{contract.contractInstallment.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td className="border px-4 py-2">{contract.contractPieces}</td>
                            <td className="border px-4 py-2">{contract.contractPiecePrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td className="border px-4 py-2">{contract.restContractPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</td>
                            <td className="border px-4 py-2">{contract.details || "لا يوجد"}</td>
                            <td className="border px-4 py-2">{new Date(contract.createdAt).toLocaleDateString('en')}</td>
                            <td className="border px-4 py-2">
                                <button
                                    onClick={() => handleDelete(contract._id)}
                                    className='px-6 py-2 text-white bg-red-500 rounded-2xl hover:bg-red-600 duration-100'>
                                    حذف
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex justify-between mt-4">
                <button
                    className="bg-blue-600 text-white px-4 py-2 rounded disabled:opacity-50"
                    onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                    disabled={currentPage === 1}
                >
                    السابق
                </button>
                <span>
                    صفحة {currentPage} من {totalPages}
                </span>
                <button
                    className="bg-blue-600 text-white px-4 py-2 rounded disabled:opacity-50"
                    onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                    disabled={currentPage === totalPages}
                >
                    التالي
                </button>
            </div>
        </div>
    );
};

export default ContractHistory;
