import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const UpdateProducts = () => {
    const [products, setProducts] = useState([]);
    const [_id, set_id] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [editingProduct, setEditingProduct] = useState(null);
    const [newProductName, setNewProductName] = useState('');
    const [newQuantity, setNewQuantity] = useState('');
    const [newBuyPrice, setNewBuyPrice] = useState('');
    const [newSellPrice, setNewSellPrice] = useState('');
    const [newPlace, setNewPlace] = useState('');
    const [newCode, setNewCode] = useState('');
    const [places, setPlaces] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const productsPerPage = 20;

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/products`);
            setProducts(response.data.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const fetchPlaces = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/place`);
            setPlaces(response.data.data);
        } catch (error) {
            console.error('Error fetching places:', error);
        }
    };


    const handleEditClick = (product) => {
        setEditingProduct(product);
        set_id(product._id);
        setNewProductName(product.product);
        setNewQuantity(product.quantity);
        setNewBuyPrice(product.buyPrice);
        setNewSellPrice(product.sellPrice);
        setNewPlace(product.place._id);
        setNewCode(product.code);
    };

    const handleSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}add/update/product`, {
                _id,
                code: newCode,
                product: newProductName,
                quantity: newQuantity,
                buyPrice: newBuyPrice,
                sellPrice: newSellPrice,
                place: newPlace
            });
            setEditingProduct(null);
            fetchProducts();
        } catch (error) {
            console.error('Error updating product:', error);
            setErrorMessage(error.response?.data?.message || 'حدث خطأ أثناء تحديث المنتج');
        }
    };

    const filteredProducts = products.filter((product) =>
        product.product.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const navigate = useNavigate('')
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin()
    }, [])
    useEffect(() => {
        fetchProducts();
        fetchPlaces();
    }, []);

    return (
        <div className="p-6">
            <h1 className="text-3xl text-yellow-500 mb-6">إدارة المنتجات</h1>

            <input
                type="text"
                className="border-b-2 border-yellow-500 w-full mb-6 p-2 focus:outline-none"
                placeholder="ابحث عن المنتج"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />

            <table className="w-full text-right">
                <thead>
                    <tr>
                        <th className="text-yellow-500 p-2">كود المنتج</th>
                        <th className="text-yellow-500 p-2">اسم المنتج</th>
                        <th className="text-yellow-500 p-2">الكمية</th>
                        <th className="text-yellow-500 p-2">سعر الشراء</th>
                        <th className="text-yellow-500 p-2">سعر البيع</th>
                        <th className="text-yellow-500 p-2">المكان</th>
                        <th className="text-yellow-500 p-2">إجراءات</th>
                    </tr>
                </thead>
                <tbody>
                    {currentProducts.map((product) => (
                        <tr key={product._id}>
                            <td className="p-2">{product.code}</td>
                            <td className="p-2">{product.product}</td>
                            <td className="p-2">{product.quantity}</td>
                            <td className="p-2">{product.buyPrice}</td>
                            <td className="p-2">{product.sellPrice}</td>
                            <td className="p-2">{product.place.place}</td>
                            <td className="p-2">
                                <button
                                    onClick={() => handleEditClick(product)}
                                    className="text-yellow-500 underline"
                                >
                                    تعديل
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="mt-4">
                <ul className="flex justify-center">
                    {Array.from({ length: Math.ceil(filteredProducts.length / productsPerPage) }, (_, index) => (
                        <li key={index} className="mx-1">
                            <button
                                onClick={() => paginate(index + 1)}
                                className={`px-3 py-1 border ${currentPage === index + 1 ? 'bg-yellow-500 text-white' : 'border-yellow-500 text-yellow-500'}`}
                            >
                                {index + 1}
                            </button>
                        </li>
                    ))}
                </ul>
            </div>

            {editingProduct && (
                <div className="mt-6">
                    <h2 className="text-xl text-yellow-500 mb-4">تعديل المنتج</h2>

                    <div className="mb-4">
                        <label className="text-yellow-500">كود المنتج:</label>
                        <input
                            type="text"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newCode}
                            onChange={(e) => setNewCode(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="text-yellow-500">اسم المنتج:</label>
                        <input
                            type="text"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newProductName}
                            onChange={(e) => setNewProductName(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="text-yellow-500">الكمية:</label>
                        <input
                            type="number"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newQuantity}
                            onChange={(e) => setNewQuantity(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="text-yellow-500">سعر الشراء:</label>
                        <input
                            type="number"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newBuyPrice}
                            onChange={(e) => setNewBuyPrice(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="text-yellow-500">سعر البيع:</label>
                        <input
                            type="number"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newSellPrice}
                            onChange={(e) => setNewSellPrice(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="text-yellow-500">المكان:</label>
                        <select
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newPlace}
                            onChange={async (e) => {
                                setNewPlace(e.target.value);
                            }}
                        >
                            <option value="">اختر المكان</option>
                            {places.map((place) => (
                                <option key={place._id} value={place._id}>
                                    {place.place}
                                </option>
                            ))}
                        </select>
                    </div>


                    <button
                        onClick={handleSave}
                        className="text-yellow-500 underline mt-4"
                    >
                        حفظ
                    </button>

                    {errorMessage && (
                        <div className="text-red-500 text-center mt-6">
                            {errorMessage}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default UpdateProducts;
